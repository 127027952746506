<template>
  <v-app-bar app flat>
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="toggleDrawer" />

    <v-container class="mx-auto py-0">
      <v-row align="center">
        <!-- Icon -->
        <v-img
          :src="require('@/assets/logo.png')"
          :alt='$t("title")'
          class="mr-8"
          contain
          height="56"
          max-width="160"
          @click="$vuetify.goTo(0)"
        />
        <!-- Title -->
        <!-- <v-app-bar-title class="mr-8">{{ $t("title") }}</v-app-bar-title> -->

        <!-- Menu -->
        <v-btn
          v-for="(link, i) in links"
          :key="i"
          v-bind="link"
          class="hidden-sm-and-down"
          text
          rounded
          @click="onMenuClick($event, link)"
        >
          {{ $t(link.text) }}
        </v-btn>

        <v-spacer></v-spacer>

        <!-- Language -->
        <!-- <v-btn icon>
          <v-icon @click="changeLocale">mdi-web</v-icon>
        </v-btn>
        <div class="caption">{{ $t("language")}}</div> -->
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreAppBar",

  computed: {
    ...mapGetters(["links"]),
  },

  methods: {
    ...mapMutations(["toggleDrawer"]),
    // メニュー選択
    onMenuClick(e, item) {
      e.stopPropagation();

      if (item.to || !item.href) return;

      this.$vuetify.goTo(item.href.endsWith("#") ? 0 : item.href);
    },
    // 言語切替
    changeLocale() {
      // this.$emit('change-locale');
      this.$i18n.locale = this.$i18n.locale === "ja" ? "en" : "ja";
    },
  },
};
</script>
